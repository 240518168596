import React, { Component } from 'react';
import Router from 'next/router';
import Container from "react-bootstrap/Container";
import dynamic from "next/dynamic";
const CustomButton = dynamic(() => import('../common/CustomButton'));
const NotFoundSvg = dynamic(() => import('./NotFoundSvg'))
import { withTranslation } from 'next-i18next';
// styles
import styles from "./NotFound.module.scss";

class NotFound extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currLocale: process.env.defaultLanguage,
            isNewsPage: false
        }
    }

    componentDidMount() {
        const router = Router && Router.router;
        const path = Router && Router.router && Router.router.asPath
        this.setState({
            isNewsPage: path && (path.includes('/news') || path.includes('/article') || path.includes('/editor')),
            currLocale: router && router.locale && router.locale !== 'default' ? router.locale : process.env.defaultLanguage
        })
    }

    render() {

        const { currLocale, t } = this.props;

        return (
            <div className="section">
                <Container>
                    <div className={`text-center ${styles.notFound_details}`}>
                        <div className={`light ${styles.mainSvg}`}>
                            <NotFoundSvg svg="light" />
                        </div>
                        <div className={`dark ${styles.mainSvg}`}>
                            <NotFoundSvg />
                        </div>
                        <div className={`h3 ${styles.head}`}>{t('common:not_found_title')}</div>
                        <p className={styles.text}>{t('common:not_found_desc')}</p>
                        <CustomButton isSubscribe={true} href={this.state.isNewsPage ? `/${currLocale}/news` : `/${currLocale}/nft`} name={t('common:home_page')} />
                    </div>
                </Container>
            </div>
        )
    }
}
export default withTranslation()(NotFound)
